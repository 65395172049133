import { type FC, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  Container,
  Button,
  Box,
  Typography,
  Modal,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  TextField
} from '@mui/material';
import { v4 as uuidv4 } from "uuid";
import { styled } from '@mui/system';
import { type RootState, setLoadFrom, addNewNotification, setAnnotations } from '../store';
import type { Label, CompanyTemplate } from '../types';
import {getAccountInfo, useAccount, useAzureGarbageCollector} from "../utils";
import { msalInstance } from "../index";
import {
  addEntityToTable,
  addMessageToQueue,
  deleteEntityFromTable,
  getEntityFromTable,
  uploadDataToBlob
} from "../api/queueApi";
import { getSasTokenUriForBlob, getSasTokenUriForQueue, getSasTokenUriForTableEntity, getTemplatesList } from "../api";
import { azureContainerName, azureFunctionQueueCode, azureFunctionBlobCode, azureFunctionTableCode, azureTableName, maxAttempts, timeoutDelay } from "../constants";
import { Preloader } from "../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/free-solid-svg-icons";


interface QuestionAnswersProps {
  annotation: Label;
  index: number;
}

const StickyHeader = styled(Box)(({theme}) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  position: 'sticky',
  top: 0,
  background: '#FFFFFF',
  padding: '10px 0',
  zIndex: 5
}))

const StyledButton = styled(Button)(({ theme }) => ({
  marginBottom: 0,
}))

const OuterContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const StyledSourceBadge = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  background: 'rgb(7, 26, 67)',
  color: '#FFF',
  padding: '4px 10px',
  bottom: '-1px',
  left: '-1px',
}));

export const modalContentContainerStyles = {
  minWidth: 800,
  width: 'max-content',
  position: 'absolute',
  top: '50%',
  left: '50%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export const ReviewFinal: React.FC = () => {
  const account = useAccount();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { addRequestData, deleteTableRequest, clearBlobStorage, clearTableStorage } = useAzureGarbageCollector();

  const annotations = useSelector((state: RootState) => state.annotator.annotations);

  const [extractLoading, setExtractLoading] = useState(false);
  const [companyTemplates, setCompanyTemplates] = useState<CompanyTemplate[]>([]);
  const [selectedCompanyTemplate, setSelectedCompanyTemplate] = useState<null | CompanyTemplate>(null);
  const [openTemplateModal, setOpenTemplateModal] = useState(false);
  const [editingAnnotation, setEditingAnnotation] = useState<null | Label>(null);
  const mounted = useRef(false);

  const FileName = searchParams.get('FileName');
  const SiteId = searchParams.get('SiteId');
  const DriveId = searchParams.get('DriveId');
  const ItemId = searchParams.get('ItemId');


  const sortedAnnotations = [...annotations].sort((a, b) => a.order - b.order)

  const generatedFileName = useMemo(() => {
    const regex = /:/g;
    const timestamp = new Date();
    const fileName = FileName.substring(0, FileName.lastIndexOf('.'));
    const formattedTimestamp = timestamp.toISOString().slice(0,-5).replace(regex, '-');
    const fileExtension = selectedCompanyTemplate
      ? selectedCompanyTemplate.name.substring(selectedCompanyTemplate.name.lastIndexOf('.'))
      : FileName.substring(FileName.lastIndexOf('.'));


    const generatedFileNamePart = `_DelphiAI Generated_${account?.account.name}_${formattedTimestamp}${fileExtension}`;

    if (fileName.length + generatedFileNamePart.length > 250) {
      const availableLength = 250 - generatedFileNamePart.length;
      const cutFileName = fileName.slice(0, availableLength)

      return cutFileName + generatedFileNamePart
    }

    return fileName + generatedFileNamePart
  }, [account?.account, selectedCompanyTemplate])


  const loadTemplates = async () => {
    const result = await getTemplatesList();

    if (result.value) {
      setCompanyTemplates(result.value)
    }
  }

  useEffect(() => {
    mounted.current = true;
    dispatch(setLoadFrom('store'));

    loadTemplates();

    if (!annotations.length) {
      const annotations = JSON.parse(localStorage.getItem('annotations'));

      dispatch(setAnnotations(annotations));
    }

    return () => {
      mounted.current = false;
    }
  }, [])

  const onTemplateModalClose = () => {
    setOpenTemplateModal(false)
    setSelectedCompanyTemplate(null)
  }

  const onApplyTemplate = (template: CompanyTemplate) => {
    setOpenTemplateModal(false)
    setSelectedCompanyTemplate(template)
  }

  const onSaveAnnotation = (annotation: Label) => {
    const updatedAnnotations = [...annotations];
    const idx = updatedAnnotations.findIndex(a => a.labelId === annotation.labelId)

    updatedAnnotations.splice(idx, 1, annotation)

    dispatch(setAnnotations(updatedAnnotations))
    setEditingAnnotation(null)
  }

  const goToAnnotator = () => {
    const params = new URLSearchParams();
    params.append('FileName', FileName);
    params.append('SiteId', SiteId);
    params.append('DriveId', DriveId);
    params.append('ItemId', ItemId);
    
    dispatch(setLoadFrom('store'));

    navigate({
      pathname: '/annotator',
      search: params.toString()
    });
  };

  const selectedAnswers = (answers: any[], labelId: string) => {
    if (answers?.length === 0 || !answers) {
      return (
        <Box
          sx={{
            width: '100%',
            marginBottom: '15px',
            border: '1px solid black',
            padding: '10px',
            boxSizing: 'border-box'
          }}>
          <Typography key={labelId}>No answers were found</Typography>
        </Box>
      )
    }
    if (answers?.every(item => item.selected === false)) {
      return (
        <Box
          sx={{
            width: '100%',
            marginBottom: '15px',
            border: '1px solid black',
            padding: '10px',
            boxSizing: 'border-box'
          }}>
          <Typography key={labelId}>No answers were selected</Typography>
        </Box>
      )
    }

    return (
      <>
        {answers?.map((item, index) => {
          if (item.selected) {
            return (
              <Box
                key={`${labelId}${index}`}
                sx={{
                  position: 'relative',
                  width: '100%',
                  marginBottom: '15px',
                  border: '1px solid black',
                  padding: '10px 10px 40px',
                  boxSizing: 'border-box'
                }}>
                <Typography>{item.answer.Value}</Typography>
                <StyledSourceBadge>{`Source: ${item.badge}`}</StyledSourceBadge>
              </Box>
            )
          }
        })}
      </>
    )
  }

  const QuestionAnswerComponent: FC<QuestionAnswersProps> = ({ annotation, index }) => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', margin: '30px 0' }}>
        <Box sx={{ borderBottom: '1px solid black', marginBottom: '10px' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between'}}>
            <Typography variant='h6' sx={{ fontWeight: 'bold' }}>{`${annotation.label} #${index + 1}`}</Typography>
            {selectedCompanyTemplate && <IconButton onClick={() => setEditingAnnotation(annotation)}>
              <FontAwesomeIcon size="xs" icon={faPencil} color="rgb(30, 48, 80)" onClick={() => setEditingAnnotation(annotation)} />
            </IconButton>}
          </Box>
          {!selectedCompanyTemplate && (
            <>
              <Typography variant='h6' sx={{ fontWeight: 'bold' }}>Context:</Typography>
              <Typography>{annotation.text}</Typography>
            </>
          )}
        </Box>
        <Box sx={{ marginBottom: '10px' }}>
          <Typography variant='h6' sx={{ fontWeight: 'bold' }}>Question:</Typography>
          <Typography sx={{ fontWeight: 'bold' }}>{annotation.question}</Typography>
        </Box>
        <Typography variant='h6' sx={{ fontWeight: 'bold' }}>Answers:</Typography>
        {selectedAnswers(annotation.answers, annotation.labelId)}
      </Box>
    )
  }

  const onExtract = async () => {
    const account = await getAccountInfo(msalInstance.getActiveAccount());
    const uuid = uuidv4();
    const queueName =  selectedCompanyTemplate ? 'save-data-into-document-using-template' : 'save-data-into-document';
    let attempt = 0;

    setExtractLoading(true);

    const blob = {
      FileName: generatedFileName,
      AccessToken: account.accessToken,
      ContentItems: annotations.map((item) => {
        const answers = item.answers?.filter((answer) => answer.selected) ?? [];

        return {
          Question: item.question,
          Context: item.text,
          Answers: answers.map(({ answer }) => answer.Value)
        };
      }),
      ...(selectedCompanyTemplate ? { DocumentTemplate: { Id: selectedCompanyTemplate.id } } : {})
    };
    const blobSasToken = await getSasTokenUriForBlob(azureContainerName, uuid, azureFunctionBlobCode, account.idToken);
    uploadDataToBlob(azureContainerName, uuid, blob, blobSasToken);
    addRequestData('blob', { containerName: azureContainerName, blobName: uuid, sasToken: blobSasToken });


    const tableItem = {
      partitionKey: queueName,
      rowKey: uuid,
      Body: uuid,
    };
    const tableSasToken = await getSasTokenUriForTableEntity(azureTableName, azureFunctionTableCode, uuid, queueName, account.idToken);
    addEntityToTable(tableItem, tableSasToken);
    addRequestData('table', { partitionKey: queueName, rowKey: uuid, sasToken: tableSasToken });


    const message = {
      TablePartitionKey: queueName,
      TableRowKey: uuid,
    };
    const queueSasToken = await getSasTokenUriForQueue(azureFunctionQueueCode, queueName, account.idToken);
    addMessageToQueue(queueName, JSON.stringify(message), queueSasToken);

    while (attempt < maxAttempts && mounted.current) {
      try {
        const res = await getEntityFromTable(tableItem.partitionKey, tableItem.rowKey, tableSasToken);

        if (res.ResponseErrorMessage) {
          const notification = (
            <pre>
              An error occurred while attempting to save your document. Please try the following: <br />
              1) Check your internet connection. <br />
              2) Ensure there is sufficient storage space available. <br />
              3) Try again to save document.
            </pre>
          );

          setExtractLoading(false);
          dispatch(addNewNotification(notification));
          deleteEntityFromTable(tableItem.partitionKey, tableItem.rowKey, tableSasToken);
          deleteTableRequest(tableItem.rowKey);
          break;
        }

        if (res && res.Response) {
          const fileUrl = res.Response;
          const notification = (
            <div>
              <p>File successfully uploaded <a href={fileUrl} target="_blank">Open file</a></p>
            </div>
          );
          
          setExtractLoading(false);

          window.open(fileUrl, '_blank');
          dispatch(addNewNotification(notification))
          deleteEntityFromTable(tableItem.partitionKey, tableItem.rowKey, tableSasToken);
          deleteTableRequest(tableItem.rowKey);
          break;
        }
      } catch (error) {
        console.error('Error retrieving entity from table:', error);
      }

      attempt++;
      await new Promise(resolve => setTimeout(resolve, timeoutDelay));
    }

    if (attempt === maxAttempts) {
      console.error('Maximum retries reached, no Response found');
    }

    clearTableStorage();
    clearBlobStorage();
  };

  if (extractLoading) {
    return <Preloader classes={['fullscreen']} />;
  }

  return (
    <>
      <OuterContainer>
        <StickyHeader>
          <Box>
            <Typography variant='h5' sx={{ maxWidth: '850px' }}>{generatedFileName}</Typography>
            {selectedCompanyTemplate && <Typography variant='h6' sx={{ maxWidth: '850px' }}>{`Applied template: ${selectedCompanyTemplate.name}`}</Typography>}
          </Box>
          <Container sx={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '350px' }}>
            <StyledButton variant="contained" color="primary" onClick={goToAnnotator}>
              Back
            </StyledButton>
            <StyledButton variant="contained" color="primary" onClick={onExtract}>
              {selectedCompanyTemplate ? 'Save with Company Template' : 'Save as Word Document'}
            </StyledButton>
            <StyledButton variant="contained" color="primary" onClick={() => selectedCompanyTemplate ? setSelectedCompanyTemplate(null) : setOpenTemplateModal(true)}>
              {selectedCompanyTemplate ? 'Withdraw Template' : 'Select Company Template'}
            </StyledButton>
          </Container>
        </StickyHeader>
        {sortedAnnotations.map((item, index) => <QuestionAnswerComponent key={item.labelId} annotation={item} index={index} />)}
      </OuterContainer>
      {openTemplateModal && <SelectTemplateModal companyTemplates={companyTemplates} onApply={onApplyTemplate} onClose={onTemplateModalClose} />}
      {!!editingAnnotation && (
        <EditAnnotationModal
          open
          annotation={editingAnnotation}
          onClose={() => setEditingAnnotation(null)}
          onSave={onSaveAnnotation}
        />
      )}
    </>
  );
};


type SelectTemplateModalProps = {
  companyTemplates: CompanyTemplate[]
  onApply: (template: CompanyTemplate) => void
  onClose: () => void
}

function SelectTemplateModal({ companyTemplates, onApply, onClose }: SelectTemplateModalProps) {
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const onChange = (e: any) => {
    const selectedTemplate = companyTemplates.find(template => template.id === e.target.value)

    setSelectedTemplate(selectedTemplate)
  }

  return (
    <Modal open>
      <Container sx={modalContentContainerStyles}>
        <Typography variant="h6" mb="20px" textAlign="center">
          Select company template name
        </Typography>
        <FormControl fullWidth>
          <InputLabel id="templates">Templates</InputLabel>
          <Select
            id="templates"
            labelId="templates"
            value={selectedTemplate?.id}
            label="Template"
            onChange={onChange}
          >
            {companyTemplates.map(template => <MenuItem value={template.id}>{template.name}</MenuItem>)}
          </Select>
          <Container sx={{ marginTop: '50px', p: '0 !important', display: 'flex', justifyContent: 'space-between'}}>
            <StyledButton variant="contained" color="primary" onClick={onClose}>
              Cancel
            </StyledButton>
            <StyledButton variant="contained" color="primary" disabled={!selectedTemplate} onClick={() => onApply(selectedTemplate)}>
              Apply
            </StyledButton>
          </Container>
        </FormControl>
      </Container>
    </Modal>
  )
}


type EditAnnotationModalProps = {
  open: boolean
  annotation: Label
  onClose: () => void
  onSave: (annotation: Label) => void
}

function EditAnnotationModal({ open, annotation, onClose, onSave }: EditAnnotationModalProps) {
  const [question, setQuestion] = useState(annotation.question)
  const [answers, setAnswers] = useState<{ [key: string]: string }>({})

  const onSubmit = () => {
    const updatedAnnotation = { ...annotation };

    updatedAnnotation.question = question
    updatedAnnotation.answers = updatedAnnotation.answers.map((answer, idx) => ({
      ...answer,
      answer: {
        ...answer.answer,
        Value: answers[idx] ?? answer.answer.Value
      }
    }))

    onSave(updatedAnnotation)
  }

  if (!open) {
    return null;
  }

  return (
    <Modal open>
      <Container sx={modalContentContainerStyles}>
        <Typography variant="h6" mb="20px" textAlign="center">
          Edit annotation
        </Typography>
        <Box mt="50px" width="100%" display="flex" flexDirection="column" gap="15px">
          <TextField
            fullWidth
            label="Question"
            variant="outlined"
            value={question}
            onChange={e => setQuestion( e.target.value)}
          />
          {annotation.answers.map((answer: any, idx) => (
            <div key={idx}>
              <TextField
                fullWidth
                multiline={true}
                rows={5}
                variant="outlined"
                label={`Answer №${idx + 1}`}
                value={answers[idx] ?? answer?.answer.Value}
                onChange={e => setAnswers({ ...answers, [idx]: e.target.value })} />
            </div>
          ))}
        </Box>
        <Container sx={{ marginTop: '50px', p: '0 !important', display: 'flex', justifyContent: 'space-between'}}>
          <StyledButton variant="contained" color="primary" onClick={onClose}>
            Cancel
          </StyledButton>
          <StyledButton variant="contained" color="primary" onClick={onSubmit}>
            Save
          </StyledButton>
        </Container>
      </Container>
    </Modal>
  )
}